import { calculate } from "./calculate";

// alert('omg');

// JS Wskazuje na wartość, nie przechowuje wartości.
// W sensie to wskaznik na wartość, tak jak w Pythonie
const STAŁA = "Jarek";
let ZMIENNA = "Jarek";

console.log(`Siema ${STAŁA}!`);

// String, Number, Bigint, boolean
// null wartość celowo niezdefiniowana
// undefined - brak definicji
// *symbol - tworzy unikalne opakowanie

let x = document.querySelector("h1");
console.log(x);

// Func

// function calculate(myNumber){
//     return myNumber * 7
// }

console.log(calculate(5));

// (Fat) Arrow Functions
// Nie tworzy kontekstu THIS, nie ma ARGUMENTS i SUPER
// Skraca zapis prostych funkcji
// Funkcja strzałkowa

const calculateArrow = (myNumber) => {
  return myNumber * 7;
};

const calculateSimpleArrow = (myNumber) => myNumber * 7;

// Obiekty

const deathStar = {
  diameter: 1000,
  name: "Death Star",
  population: 10000,
  isOperation: true,
  fire: (target) => {
    console.log(`Fire at ${target}`);
  },
  commander: {
    name: "Darth Vader",
  },
};

// zmienne -> własności (property)
// funkcje -> metody (methods)
// obiekty -> własności i metody

console.log(deathStar);
console.log(deathStar.name);

deathStar.fire("Earth");

deathStar["isOperation"];

deathStar.commander.name;

// Logika

if ("2" == 2) {
  console.log("true");
} // test prosty bez weryfikacji typu

if ("2" === 2) {
  console.log("true");
} // weryfikacja typeof + wartość

if (2 && 3) {
  console.log("true");
} // and

if (2 || 3) {
  console.log("true");
} // or

if (!2) {
  console.log("true");
}

if (true) {
  console.log("true");
} else {
  console.log("false");
}

if (true) {
  console.log("true");
} else if (false) {
  console.log("false");
} else {
  console.log("false");
}

let myVariable = 4;

switch (myVariable) {
  case 1:
    console.log("1");
    break;
  case 2:
    console.log("2");
    break;
  case 3:
    console.log("3");
    break;
  default:
    console.log("default");
    break;
}

true ? console.log("true") : console.log("false");

// obsługa eventów

function handleClick(e) {
  // e to PointerEvent z danymi na temat wydarzenia
  console.log(e);

  console.log("click");
  header = document.querySelector("h1");
  header.textContent = "Zmień tekst nagłówka";
}

let button = document.querySelector(".header__button--js");
button.addEventListener("click", handleClick);

button.addEventListener("click", (e) => {
  header = document.querySelector("h1");
  header.innerHTML = "Zmień tekst nagłówka2";
});

// Hamburger

button.classList.add("testowa");
console.log(button.classList);

hamburger = document.querySelector(".hamburger");
hamburger.addEventListener("click", () => {
  navigation = document.querySelector(".nav");
  navigation.classList.toggle("flex");
});

import * as date from "date-fns";

console.log(date.format(new Date(), "yyyy-MM-dd"));

// // array

const hardware = ["keyboard", "mouse", "monitor"];
console.log(hardware);
console.log(hardware[1]);

hardware.push({}); //dodaj na koniec
hardware.unshift({}); // dodaj na początek

hardware.shift(); // usuwa element z początku
hardware.pop(); // usuwa z końca

hardware.indexOf("keyboard"); // sprawdzenie czy element jest w liscie

const start = 1;
hardware.splice(start, 3); // usuwanie n elementów od danej pozyji
hardware.slice(start, 3); // pobieranie elementów

// // pętle

for (let i = 0; i < 10; i++) {
  console.log(i);
}

for (let i = 0; i < hardware.length; i++) {
  console.log(`na miejscu ${i} jest ${hardware[i]}`);
}

var i = 1;
while (i < 5) {
  console.log(i);
  i++;
}

var i = 1;
do {
  console.log(i);
  i++;
} while (i < 5);

// for in dla słowników

for (item in deathStar) {
  // po kluczach
  console.log(item);
}

for (item in deathStar) {
  // po wartościach
  console.log(deathStar[item]);
}

// for of dla list

for (item of hardware) {
  console.log(item);
}

// LOCAL STORAGE

document.cookie = "key=value;";
console.log(document.cookie);

// localstorage - wirtualny dysk przeglądarki, trawły
// sessionStorage - do zamknięcia sesji, przeglądarki

localStorage.setItem("key", "value");
console.log(localStorage.getItem("key"));

// jeśli chcemy wrzucić słownik to musimy go przerobić na jsona

localStorage.setItem("key", JSON.stringify(deathStar));

// destrukturyzacja, utworzenie wskaznikow na zmiennie z niego

const { name, commander } = deathStar;

// spread operator

const plane = {
  engine: "v12",
};

const full_starship = {
  ...deathStar,
  ...plane,
};

const software = ["vscode"];
const stuff = [...software, ...hardware];

const newSoftware = [...software, ...hardware];
